var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './tariff-card.scss';
import { Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { TARIFF_CARD_BASE_CLASSNAME, TARIFF_CARD_CARD_CONTAINER_CLASSNAME, TARIFF_CARD_CARD_CONTAINER_WITH_FLAGBADGE_CLASSNAME, TARIFF_CARD_FLAG_BADGE_CLASSNAME, TARIFF_CARD_HAS_FLAGBADGE_CLASSNAME, TARIFF_CARD_HEADLINE_CLASSNAME, TARIFF_CARD_ITEM_CLASSNAME, TARIFF_CARD_TXT_CONTENT_CLASSNAME, TARIFF_CARD_WITHOUT_FLAG_BADGE_CLASSNAME, TARIFF_CARD_WRAPPER_CLASSNAME, } from './Constants';
import renderTemplate from './_tariff-card-wrapper.hbs';
import { TARIFF_CARD_TOP_SMALL_HEADLINE_CLASSNAME } from './Constants';
import { getButtonPropertiesFromDOM, getButtonLinkPropertiesFromDOM, handleClick, } from './helpers';
import { BUTTON_BASE_CLASSNAME } from '../button';
import { PromoPrice } from '@vfde-brix/ws10/promo-price';
import { FlagBadge } from '@vfde-brix/ws10/flag-badge';
import { CAROUSEL_BASE_CLASSNAME } from '@vfde-brix/ws10/carousel';
/**
 * TariffCards
 */
var TariffCards = /** @class */ (function (_super) {
    __extends(TariffCards, _super);
    function TariffCards() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * afterInit (called once after init)
     */
    TariffCards.prototype.afterInit = function () {
        var e_1, _a;
        this.tariffCard = this.containerElement.getElementsByClassName(TARIFF_CARD_BASE_CLASSNAME)[0];
        // we need to detect if the tariff-card is nested inside a carousel
        // to apply the flag-badge-logic in that case too
        var carousel = this.containerElement.closest(".".concat(CAROUSEL_BASE_CLASSNAME));
        var containerElement = carousel || this.containerElement;
        var flagbadges = containerElement.getElementsByClassName(TARIFF_CARD_FLAG_BADGE_CLASSNAME);
        if (!flagbadges.length) {
            return;
        }
        this.tariffCard.classList.add(TARIFF_CARD_HAS_FLAGBADGE_CLASSNAME);
        // if a card does not have a flag-badge
        // we should add another class
        // and set the corresponding CSS via this class
        // to eliminate the extra padding.
        var items = containerElement.getElementsByClassName(TARIFF_CARD_ITEM_CLASSNAME);
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                if (item.children.length > 1) {
                    // more than 1 child, which means we have a flagbadge (flag-badge and card-container)
                    var cardContainer = item.getElementsByClassName(TARIFF_CARD_CARD_CONTAINER_CLASSNAME)[0];
                    cardContainer.classList.add(TARIFF_CARD_CARD_CONTAINER_WITH_FLAGBADGE_CLASSNAME);
                }
                else {
                    // only one child (or 0 which should never occur), which means that the current card does not have a flag badge
                    item.classList.add(TARIFF_CARD_WITHOUT_FLAG_BADGE_CLASSNAME);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    TariffCards.prototype.initEvents = function () {
        var e_2, _a, e_3, _b;
        if (this.properties.business.onButtonsClick !== undefined) {
            try {
                // iterating through the button logic parameters in the business logic
                for (var _c = __values(Object.entries(this.properties.business.onButtonsClick)), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var _e = __read(_d.value, 2), dataId = _e[0], buttons = _e[1];
                    // selecting the current tariff card element from the DOM with data-id
                    var buttonContainer = this.containerElement.querySelector("[data-id=\"".concat(dataId, "\"]"));
                    var buttonBusinessLogic = this.properties.business.onButtonsClick[dataId];
                    try {
                        // iterating through the buttons given in business logic
                        for (var _f = (e_3 = void 0, __values(Object.keys(buttons))), _g = _f.next(); !_g.done; _g = _f.next()) {
                            var buttonType = _g.value;
                            var buttonElement = buttonContainer.querySelector(".".concat(BUTTON_BASE_CLASSNAME, "--").concat(buttonType));
                            if (buttonElement !== null) {
                                // if each card uses parameters to differentiate the onClick function,
                                // if a parameter has been given, we call the handleClick function with the parameter
                                // otherwise we call the function without a parameter on Button click
                                buttonElement.addEventListener('click', buttonBusinessLogic[buttonType].length > 0
                                    ? handleClick(buttonBusinessLogic[buttonType], dataId)
                                    : handleClick(buttonBusinessLogic[buttonType]));
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    };
    /** getDefaultProperties */
    TariffCards.prototype.getDefaultProperties = function (newProperties) {
        newProperties.optWrapper = newProperties.optWrapper === undefined ? true : newProperties.optWrapper;
        return newProperties;
    };
    /** writeDom */
    TariffCards.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /** readDom */
    TariffCards.prototype.readDom = function (tariffCardBusinessLogic) {
        var e_4, _a;
        var _b;
        var wrapperElements = this.containerElement.getElementsByClassName((TARIFF_CARD_WRAPPER_CLASSNAME));
        var optWrapper = Boolean(wrapperElements.length);
        var properties = {
            items: [],
            business: tariffCardBusinessLogic,
            optWrapper: optWrapper,
        };
        this.tariffCardItemElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(TARIFF_CARD_ITEM_CLASSNAME)), false);
        try {
            for (var _c = __values(this.tariffCardItemElements), _d = _c.next(); !_d.done; _d = _c.next()) {
                var itemElement = _d.value;
                var business = {};
                var containerFlagBadge = FlagBadge.getPropertiesFromDom(itemElement);
                var stdHeadlineTopSmall = itemElement.getElementsByClassName(TARIFF_CARD_TOP_SMALL_HEADLINE_CLASSNAME)[0].textContent.trim();
                var stdHeadline = itemElement.getElementsByClassName(TARIFF_CARD_HEADLINE_CLASSNAME)[0].textContent.trim();
                var containerPromoPrice = PromoPrice.getPropertiesFromDom(itemElement);
                var containerButtons = getButtonPropertiesFromDOM(itemElement);
                var containerButtonLinks = getButtonLinkPropertiesFromDOM(itemElement);
                var stdProductId = itemElement.getAttribute('data-id');
                var txtContent = (_b = itemElement.getElementsByClassName(TARIFF_CARD_TXT_CONTENT_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.innerHTML.trim();
                properties.items.push(removeUndefinedFieldsFromObject({
                    business: business,
                    containerFlagBadge: containerFlagBadge,
                    stdHeadlineTopSmall: stdHeadlineTopSmall,
                    stdHeadline: stdHeadline,
                    containerPromoPrice: containerPromoPrice,
                    containerButtons: containerButtons,
                    containerButtonLinks: containerButtonLinks,
                    stdProductId: stdProductId,
                    txtContent: txtContent,
                }));
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return properties;
    };
    /**
     * Get all IDs from tariff card items
     */
    TariffCards.prototype.getAllPossibleIds = function () {
        return this.properties.items.map(function (item) { return item.stdProductId; });
    };
    return TariffCards;
}(Pattern));
export { TariffCards };
/**
 * This function returns an instance of TariffCard(s)
 */
export var createTariffCards = function (containerElement, businessLogicOrProperties) {
    var tariffCards = new TariffCards(containerElement, businessLogicOrProperties);
    tariffCards.init();
    return tariffCards;
};
