/**
 * Class base name
 */
export var ACCORDION_BASE_CLASSNAME = 'ws10-accordion';
/**
 * Base class for item
 */
export var ACCORDION_ITEM_BASE_CLASSNAME = "".concat(ACCORDION_BASE_CLASSNAME, "-item");
/**
 * ACCORDION_ITEM_CONTENT_ID
 */
export var ACCORDION_ITEM_CONTENT_ID = "".concat(ACCORDION_ITEM_BASE_CLASSNAME, "__content-");
/**
 * export ACCORDION_CHEVRON_CLASSNAME
 */
export var ACCORDION_ITEM_CHECKBOX = "".concat(ACCORDION_ITEM_BASE_CLASSNAME, "__checkbox");
/**
 * ACCORDION_ITEM_HEADLINE_CLASSNAME
 */
export var ACCORDION_ITEM_HEADLINE_CLASSNAME = "".concat(ACCORDION_ITEM_BASE_CLASSNAME, "__headline");
/**
 * ACCORDION_ITEM_TAB_CLASSNAME
 */
export var ACCORDION_ITEM_TAB_CLASSNAME = "".concat(ACCORDION_ITEM_BASE_CLASSNAME, "__tab");
/**
 * ACCORDION_ITEM_TAB_CLASSNAME
 */
export var ACCORDION_ITEM_ICON_CLASSNAME = "".concat(ACCORDION_ITEM_BASE_CLASSNAME, "__icon");
/**
 * ACCORDION_ITEM_LABEL_CLASSNAME
 */
export var ACCORDION_ITEM_LABEL_CLASSNAME = "".concat(ACCORDION_ITEM_BASE_CLASSNAME, "__label");
/**
 * Classname of the items content wrapper
 */
export var ACCORDION_ITEM_CONTENT_CLASSNAME = "".concat(ACCORDION_ITEM_BASE_CLASSNAME, "__content");
/**
 * Classname of the items content wrapper
 */
export var ACCORDION_ITEM_CONTENT_BOX_CLASSNAME = "".concat(ACCORDION_ITEM_BASE_CLASSNAME, "__content-box");
/**
 * export ACCORDION_CHEVRON_CLASSNAME
 */
export var ACCORDION_OPEN_INDIVIDUALLY_JS_CLASSNAME = 'js-open-individually';
/**
 * export ACCORDION_CHEVRON_CLASSNAME
 */
export var ACCORDION_JUMP_TO_CLICKED_ACCORDION = 'js-jump-to-clicked-item';
/**
 * ACCORDION_CHECKBOX
 */
export var ACCORDION_CHECKBOX = 'ws10-accordion-input-';
/**
 * ACCORDION_ITEM
 */
export var ACCORDION_ITEM = 'ws10-accordion-item';
/**
 * OPENED
 */
export var OPENED = 'opened';
/**
 * OPEN_KEY
 */
export var OPEN_KEY = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ENTER: 'Enter',
};
/**
 * Name of the accordion param
 */
export var ACCORDION_PARAM_NAME = 'accordion';
/**
 * Variants of a interaction list
 */
export var AccordionVariant;
(function (AccordionVariant) {
    AccordionVariant["Flat"] = "flat";
    AccordionVariant["Card"] = "card";
})(AccordionVariant || (AccordionVariant = {}));
/**
 * setTimeout_value
 */
export var TIMEOUT_VALUE = 500;
/**
 * ACCORDION_ITEM_CARD
 */
export var ACCORDION_ITEM_CARD = 'ws10-accordion-item__card';
/**
 * ACCORDION_ITEM_FLAT
 */
export var ACCORDION_ITEM_FLAT = 'ws10-accordion-item__flat';
