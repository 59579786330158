var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " ws10-is-hidden";
},"3":function(container,depth0,helpers,partials,data) {
    return " ws10-sticky-price-bar--intersecting";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"stickyPriceBarItems") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":20,"column":21}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-sticky-price-bar__item\">\n                    <div class=\"ws10-sticky-price-bar__item-text ws10-text-small-inverse\">\n                        "
    + alias2(((helper = (helper = lookupProperty(helpers,"stdText") || (depth0 != null ? lookupProperty(depth0,"stdText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"stdText","hash":{},"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":37}}}) : helper)))
    + "\n                    </div>\n                    <div class=\"ws10-sticky-price-bar__item-price-section ws10-text-large-inverse\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optPriceConditionBefore") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":14,"column":31}}})) != null ? stack1 : "")
    + "                        <span class=\"ws10-sticky-price-bar__price\">\n                            "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"euro") : stack1), depth0))
    + ","
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"cent") : stack1), depth0))
    + " &euro;\n                        </span>\n                    </div>\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"ws10-sticky-price-bar__condition-before\">\n                                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optPriceConditionBefore") || (depth0 != null ? lookupProperty(depth0,"optPriceConditionBefore") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optPriceConditionBefore","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":59}}}) : helper)))
    + "\n                            </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-sticky-price-bar"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stickyPriceBarItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":97}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isIntersecting") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":97},"end":{"line":1,"column":162}}})) != null ? stack1 : "")
    + "\" aria-live=\"polite\">\n    <div class=\"ws10-sticky-price-bar__content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stickyPriceBarItems") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true});