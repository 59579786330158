/**
 * Any constants you need belong in this file. These are normally used for selectors.
 * The advantage of this is that you can use these same constant in your validator, tests
 * and main ts file.
 */
/**
 * Auto suggestion classname
 */
export var AUTO_SUGGESTION_BASE_CLASSNAME = 'ws10-auto-suggestion';
/**
 * section item
 */
export var AUTO_SUGGESTION_SECTION_ITEM_CLASSNAME = "".concat(AUTO_SUGGESTION_BASE_CLASSNAME, "__section-item");
/**
 * section
 */
export var AUTO_SUGGESTION_SECTIONS_CLASSNAME = "".concat(AUTO_SUGGESTION_BASE_CLASSNAME, "__sections");
/**
 * section header
 */
export var AUTO_SUGGESTION_SECTION_HEADER_CLASSNAME = "".concat(AUTO_SUGGESTION_BASE_CLASSNAME, "__section-header");
/**
 * Button container
 */
export var AUTO_SUGGESTION_BUTTON_CONTAINER = "".concat(AUTO_SUGGESTION_BASE_CLASSNAME, "__button-container");
/**
 * Auto suggestion item classname
 */
export var CLASSNAME_AUTO_SUGGESTION_ITEM = 'ws10-auto-suggestion-item';
/**
 * Search auto suggestion item image
 */
export var AUTO_SUGGESTION_ITEM_IMAGE_CLASSNAME = "".concat(CLASSNAME_AUTO_SUGGESTION_ITEM, "__image");
/**
 * Search auto suggestion item text, it could be a header or a long test
 */
export var AUTO_SUGGESTION_ITEM_TEXT_CLASSNAME = "".concat(CLASSNAME_AUTO_SUGGESTION_ITEM, "__text");
/**
 * Search auto suggestion item link
 */
export var AUTO_SUGGESTION_ITEM_LINK_CLASSNAME = "".concat(CLASSNAME_AUTO_SUGGESTION_ITEM, "__link");
/**
 * Offer price container
 */
export var AUTO_SUGGESTION_PROMO_PRICE_CLASSNAME = "".concat(CLASSNAME_AUTO_SUGGESTION_ITEM, "__promo-price-container");
