var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import './color-swatch.scss';
import renderTemplate from './_color-swatch.hbs';
import { Pattern, } from '@vfde-brix/ws10/core';
import { CLASSNAME_COLOR, CLASSNAME_ITEM, } from './Constants';
/**
 * Color swatch component
 */
var ColorSwatch = /** @class */ (function (_super) {
    __extends(ColorSwatch, _super);
    function ColorSwatch() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * No default props, so just return
     */
    ColorSwatch.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Render color swatch
     */
    ColorSwatch.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * This component is CMS enabled so we need readDOM
     */
    ColorSwatch.prototype.readDom = function (colorSwatchBusinessLogic) {
        var e_1, _a;
        var properties = {
            items: [],
            business: colorSwatchBusinessLogic,
        };
        var colorSwtachItems = __spreadArray([], __read(this.containerElement.getElementsByClassName(CLASSNAME_ITEM)), false);
        try {
            for (var colorSwtachItems_1 = __values(colorSwtachItems), colorSwtachItems_1_1 = colorSwtachItems_1.next(); !colorSwtachItems_1_1.done; colorSwtachItems_1_1 = colorSwtachItems_1.next()) {
                var colorSwatchItem = colorSwtachItems_1_1.value;
                var stdColorName = colorSwatchItem.getAttribute('title') || undefined;
                var colorSpan = colorSwatchItem.getElementsByClassName(CLASSNAME_COLOR)[0];
                var rgbColor = colorSpan.style.backgroundColor;
                var rgbColorArray = rgbColor.substring(4, rgbColor.length - 1).split(',');
                var stdColorRgbValue = rgbColorArray.toString();
                properties.items.push({
                    stdColorRgbValue: stdColorRgbValue,
                    stdColorName: stdColorName,
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (colorSwtachItems_1_1 && !colorSwtachItems_1_1.done && (_a = colorSwtachItems_1.return)) _a.call(colorSwtachItems_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return properties;
    };
    return ColorSwatch;
}(Pattern));
export { ColorSwatch };
/**
 * This function returns an instance of ColorSwatch
 */
export var createColorSwatch = function (containerElement, businessLogicOrProperties) {
    var colorSwatch = new ColorSwatch(containerElement, businessLogicOrProperties);
    colorSwatch.init();
    return colorSwatch;
};
