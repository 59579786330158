var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "ws10-image-header--split ws10-image-header--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optContentColumns") || (depth0 != null ? lookupProperty(depth0,"optContentColumns") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optContentColumns","hash":{},"data":data,"loc":{"start":{"line":1,"column":164},"end":{"line":1,"column":185}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"ws10-image-header__split-container\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"ws10-belt-container\">\n            <div class=\"ws10-belt-container__belt\">\n                <div class=\"ws10-grid\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"optSolidBackground") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":137},"end":{"line":16,"column":214}}})) != null ? stack1 : "")
    + " ws10-grid__inner";
},"8":function(container,depth0,helpers,partials,data) {
    return " ws10-image-header__content-container--solid";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-column ws10-column--12 ws10-column--medium-"
    + alias4(((helper = (helper = lookupProperty(helpers,"optContentColumns") || (depth0 != null ? lookupProperty(depth0,"optContentColumns") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optContentColumns","hash":{},"data":data,"loc":{"start":{"line":17,"column":123},"end":{"line":17,"column":144}}}) : helper)))
    + " ws10-column--large-"
    + alias4(((helper = (helper = lookupProperty(helpers,"optContentColumns") || (depth0 != null ? lookupProperty(depth0,"optContentColumns") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optContentColumns","hash":{},"data":data,"loc":{"start":{"line":17,"column":164},"end":{"line":17,"column":185}}}) : helper)))
    + " ws10-column--x-large-"
    + alias4(((helper = (helper = lookupProperty(helpers,"optContentColumns") || (depth0 != null ? lookupProperty(depth0,"optContentColumns") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optContentColumns","hash":{},"data":data,"loc":{"start":{"line":17,"column":207},"end":{"line":17,"column":228}}}) : helper)));
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ws10-image-header__highlight-badge\">\n"
    + ((stack1 = container.invokePartial(require("../highlight-badge/_highlight-badge.hbs"),(depth0 != null ? lookupProperty(depth0,"containerHighlightBadge") : depth0),{"name":"_highlight-badge","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"ws10-image-header__headline"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"optBlackText") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":67},"end":{"line":25,"column":129}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../headline/_headline.hbs"),(depth0 != null ? lookupProperty(depth0,"containerHeadline") : depth0),{"name":"_headline","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return " ws10-image-header__headline--black";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"ws10-image-header__subline"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optBlackText") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":70},"end":{"line":30,"column":131}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"containerSubline") : depth0)) != null ? lookupProperty(stack1,"optTag") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":31,"column":36},"end":{"line":35,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return " ws10-image-header__subline--black";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../headline/_headline.hbs"),(depth0 != null ? lookupProperty(depth0,"containerSubline") : depth0),{"name":"_headline","data":data,"indent":"                                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../just-text/_just-text.hbs"),(depth0 != null ? lookupProperty(depth0,"containerSubline") : depth0),{"name":"_just-text","hash":{"className":"ws10-text-large"},"data":data,"indent":"                                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"ws10-image-header__promo-price-container\">\n"
    + ((stack1 = container.invokePartial(require("../promo-price/_promo-price.hbs"),(depth0 != null ? lookupProperty(depth0,"containerPromoPrice") : depth0),{"name":"_promo-price","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ws10-image-header__buttons-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"containerButtons") : depth0),{"name":"each","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":28},"end":{"line":50,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <span class=\"ws10-image-header__button\" tabindex=\"0\"  focusable=\"true\">\n"
    + ((stack1 = container.invokePartial(require("../button/_button.hbs"),depth0,{"name":"_button","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </span>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "        </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "                </div>\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-image-header ws10-image-header--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optSize") || (depth0 != null ? lookupProperty(depth0,"optSize") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optSize","hash":{},"data":data,"loc":{"start":{"line":1,"column":49},"end":{"line":1,"column":60}}}) : helper)))
    + " ws10-image-header--"
    + alias4(((helper = (helper = lookupProperty(helpers,"optContentAlignment") || (depth0 != null ? lookupProperty(depth0,"optContentAlignment") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optContentAlignment","hash":{},"data":data,"loc":{"start":{"line":1,"column":80},"end":{"line":1,"column":103}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optSplit") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":104},"end":{"line":1,"column":192}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"ws10-image-header__image-text-container\">\n        <div class=\"ws10-image-header__image-container\">\n            <div class=\"ws10-image-header__picture\">\n                <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdImgSrcMobile") || (depth0 != null ? lookupProperty(depth0,"stdImgSrcMobile") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdImgSrcMobile","hash":{},"data":data,"loc":{"start":{"line":5,"column":26},"end":{"line":5,"column":45}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdImageAltText") || (depth0 != null ? lookupProperty(depth0,"stdImageAltText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdImageAltText","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":71}}}) : helper)))
    + "\" class=\"ws10-image-header__small-img\">\n                <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdImgSrcDesktop") || (depth0 != null ? lookupProperty(depth0,"stdImgSrcDesktop") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdImgSrcDesktop","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":46}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdImageAltText") || (depth0 != null ? lookupProperty(depth0,"stdImageAltText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdImageAltText","hash":{},"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":6,"column":72}}}) : helper)))
    + "\" class=\"ws10-image-header__img\">\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optSplit") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "            <div data-image-header-contentColumns=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"optContentColumns") || (depth0 != null ? lookupProperty(depth0,"optContentColumns") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optContentColumns","hash":{},"data":data,"loc":{"start":{"line":16,"column":51},"end":{"line":16,"column":72}}}) : helper)))
    + "\" class=\"ws10-image-header__content-container"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"optSplit") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":117},"end":{"line":16,"column":242}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"ws10-image-header__content"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"optSplit") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":54},"end":{"line":17,"column":239}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerHighlightBadge") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":22,"column":27}}})) != null ? stack1 : "")
    + "                        <div class=\"ws10-image-header__headline-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerHeadline") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":28},"end":{"line":28,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerSubline") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":37,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerPromoPrice") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":24},"end":{"line":43,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerButtons") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":52,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optSplit") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":55,"column":8},"end":{"line":61,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});