import { FLAG_BADGE_BASE_CLASSNAME } from '../Constants';
/**
 * get the color of flag badge
 */
export var getFlagBadgeColor = function (containerElement) {
    var flagBadgeContainer = containerElement.getElementsByClassName(FLAG_BADGE_BASE_CLASSNAME)[0];
    if (flagBadgeContainer) {
        var flagBadgeStyles = window.getComputedStyle(flagBadgeContainer);
        return flagBadgeStyles.getPropertyValue('background-color');
    }
    return undefined;
};
