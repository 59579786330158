var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr class=\"ws10-table__row\">\n                <th scope=\"row\" class=\"ws10-table__cell ws10-table__cell--header\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdLabel") || (depth0 != null ? lookupProperty(depth0,"stdLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdLabel","hash":{},"data":data,"loc":{"start":{"line":5,"column":82},"end":{"line":5,"column":96}}}) : helper))) != null ? stack1 : "")
    + "</th>\n                <td class=\"ws10-table__cell\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdText") || (depth0 != null ? lookupProperty(depth0,"stdText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdText","hash":{},"data":data,"loc":{"start":{"line":6,"column":45},"end":{"line":6,"column":58}}}) : helper))) != null ? stack1 : "")
    + "</td>\n            </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-table\">\n    <table class=\"ws10-table__table\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"listRow") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":8,"column":17}}})) != null ? stack1 : "")
    + "    </table>\n</div>\n";
},"useData":true});