import { ACCORDION_PARAM_NAME } from './Constants';
/**
 * This class is for accordions functions
 */
var AccordionParamService = /** @class */ (function () {
    function AccordionParamService() {
    }
    /**
     * getSelectedAccordion is to return the selected accordion from the param value
     */
    AccordionParamService.prototype.getAccordionFromParam = function (properties) {
        var items = properties.items;
        var activeAccordion = this.getParam(ACCORDION_PARAM_NAME);
        var accordionIndex;
        for (var i = 0; i < items.length; i++) {
            if (items[i].stdId === activeAccordion) {
                accordionIndex = i;
            }
        }
        return accordionIndex;
    };
    /**
     * toggleUrlParam
     * to add or remove the 'accordion' param value in the url according to toggle item
     */
    AccordionParamService.prototype.toggleUrlParam = function (accordionId, isOpen) {
        var url = new URL(window.location.href);
        var param = this.getParam(ACCORDION_PARAM_NAME);
        if (isOpen) {
            url.searchParams.set(ACCORDION_PARAM_NAME, accordionId);
        }
        else if (accordionId === param) {
            url.searchParams.delete(ACCORDION_PARAM_NAME);
        }
        window.history.replaceState({}, '', url.href);
    };
    /**
     * getParam
     * to return the value of a given param from the url
     */
    AccordionParamService.prototype.getParam = function (param) {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        return urlParams.get(param);
    };
    return AccordionParamService;
}());
export { AccordionParamService };
