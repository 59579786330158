var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CLASSNAME_HIDDEN, } from '@vfde-brix/ws10/styles';
import { isEqual, cloneDeep, } from 'lodash';
import { getContainerSpacing, SPACING_PREFIX, } from './utils/getContainerSpacing';
import { NO_PATTERN_BUSINESS_LOGIC } from './constants';
import { toggleElementVisibility } from './utils/toggleElementVisibility';
import { attempt } from './utils/attempt';
/**
 * The parent class of all components. Every component should extend this class.
 */
var Pattern = /** @class */ (function () {
    /**
     * Pattern Constructor
     */
    function Pattern(containerElement, businessLogicOrProperties) {
        this.containerElement = containerElement;
        this.businessLogicOrProperties = businessLogicOrProperties;
        this.isInitialized = false;
    }
    /**
     * Pattern init method
     * Must be called from within the pattern factory function.
     * If needed can be overwritten by a component specific init function
     * which internally must call `super.init()`.
     */
    Pattern.prototype.init = function () {
        if (this.isInitialized) {
            throw new Error('init() can not be called more than once');
        }
        var properties;
        // If pass PatternBusinessLogic instead of PatternProperties staticUsage is true
        var staticUsage = this.isStaticUsage(this.businessLogicOrProperties);
        if (staticUsage === true) {
            // readDom = first initDom, then readDom
            this.initDom(false, this.businessLogicOrProperties);
            properties = this.readDom(this.businessLogicOrProperties) || {};
        }
        else {
            properties = this.businessLogicOrProperties;
        }
        this.setProperties(properties);
        if (staticUsage === false) {
            // writeDom = first writeDom, then initDom
            this.writeDom();
            this.initDom(true, this.properties.business);
        }
        // run code after initialization
        this.afterInit();
        // initialize events
        this.initEvents(true);
        this.isInitialized = true;
    };
    /**
     * This method is useful for code that needs
     * to run once after component initialization
     * (but before `initEvents`). Examples:
     * properties validation / throw errors for invalid states.
     */
    Pattern.prototype.afterInit = function () { };
    /**
     * Checks if the given object has no 'business' property.
     * If it has no 'business' property, the object is expected to be of the generic type `PatternBusinessLogic`
     * and the component will be mounted statically using `readDom`.
     * If it has a 'business' property, the object is expected to be of the generic type `PatternProperties`
     * and the component will be mounted dynamically using `writeDom`.
     * @param object The object to check
     */
    Pattern.prototype.isStaticUsage = function (object) {
        return !('business' in object);
    };
    /**
     * This function compares old and new properties and if they differ it sets the properties and renders
     */
    Pattern.prototype.update = function (newProperties, preventRerender) {
        if (preventRerender === void 0) { preventRerender = false; }
        var mergedProperties = __assign(__assign({}, this.properties), newProperties);
        // If properties are unchanged then return
        if (isEqual(this.properties, mergedProperties)) {
            return;
        }
        var oldProperties = this.properties;
        // Deep Clone new properties into properties
        this.setProperties(mergedProperties);
        this.didReceiveProps(this.properties, oldProperties);
        if (!preventRerender) {
            // Render if new
            this.writeDom();
            // initialize DOM & child components
            this.initDom(true, this.properties.business);
            // initialize events
            this.initEvents(false);
        }
    };
    /**
     * Get getContainerElement
     */
    Pattern.prototype.getContainerElement = function () {
        return this.containerElement;
    };
    /**
     * Get properties
     */
    Pattern.prototype.getProperties = function () {
        return cloneDeep(this.properties);
    };
    /**
     * Get properties
     */
    Pattern.prototype.getPropertyByKey = function (key) {
        var clone = cloneDeep(this.properties);
        return clone[key];
    };
    /**
     * toggled containerElement like classList.toggle()
     * @param shouldHide is optional: when: true: containerElement is always hidden, false is always shown, undefined it toggles the className
     * @param withPrecedingHeadline If there is a headline preceding the container element, toggle it as well.
     * The headline will be detected recursively for cases when it is nested as a single child element in an element of another type.
     * @param shouldHidePrecedingHeadline This parameter is only relevant if `withPrecedingHeadline` is set to `true`.
     * It can be used to to toggle the preceding headline to a different state than the component itself.
     * For example it can be used, if you want to hide the component, but still show the preceding headline, or the other way around.
     * If omitted, the default value is the value of the `shouldHide` parameter.
     */
    Pattern.prototype.toggleContainer = function (shouldHide, withPrecedingHeadline, shouldHidePrecedingHeadline) {
        if (withPrecedingHeadline === void 0) { withPrecedingHeadline = false; }
        var shouldShow = typeof shouldHide === 'boolean' ? !shouldHide : undefined;
        toggleElementVisibility(this.containerElement, shouldShow);
        if (withPrecedingHeadline) {
            // hide preceding headline together with the element
            var shouldShowPrecedingHeadline = typeof shouldHidePrecedingHeadline === 'boolean'
                ? !shouldHidePrecedingHeadline
                : shouldShow;
            var headingElementCandidate = this.containerElement.previousElementSibling;
            var headingElement = getHeadingRecursively(headingElementCandidate);
            // if we found a headline, toggle the candidate (which can be the wrapper element or the headline itself)
            headingElement && toggleElementVisibility(headingElementCandidate, shouldShowPrecedingHeadline);
        }
    };
    /**
     * Toggle the visibility of ancestor element of component container
     * @param shouldHide (optional) When `true`: element is always hidden, `false`: is always shown, `undefined`: it toggles the className
     * @param selector (optional) When given, it travels up the DOM starting from the container element until element matching the selector is found.
     * If undefined, it takes the immediate parent element
     */
    Pattern.prototype.toggleAncestor = function (shouldHide, selector) {
        var shouldShow = typeof shouldHide === 'boolean' ? !shouldHide : undefined;
        var elementToHide = selector ? this.containerElement.closest(selector) : this.containerElement.parentElement;
        toggleElementVisibility(elementToHide, shouldShow);
    };
    /**
     * Checks if the containerElement is currently visible
     * @returns Boolean
     */
    Pattern.prototype.isContainerVisible = function () {
        return !this.containerElement.classList.contains(CLASSNAME_HIDDEN);
    };
    /**
     * Set spacing to containerElement
     */
    Pattern.prototype.setSpacing = function (spacing) {
        if (this.containerElement.classList.contains(SPACING_PREFIX + this.getSpacing())) {
            this.containerElement.classList.replace(SPACING_PREFIX + this.getSpacing(), SPACING_PREFIX + spacing);
        }
        else {
            this.containerElement.classList.add(SPACING_PREFIX + spacing);
        }
        this.containerSpacing = spacing;
    };
    /**
     * Get properties from DOM
     */
    Pattern.getPropsFromDom = function (containerElement, factory) {
        return attempt(function () {
            var component = factory(containerElement, NO_PATTERN_BUSINESS_LOGIC);
            return component.getProperties();
        });
    };
    /**
     * get Spacing from containerElement
     */
    Pattern.prototype.getSpacing = function () {
        return this.containerSpacing || getContainerSpacing(this.containerElement.className);
    };
    /**
     * Set properties by doing a deep copy of the properties so no arrays are copied by reference
     */
    Pattern.prototype.setProperties = function (mergedProperties) {
        // Get any default properties that were undefined
        mergedProperties = this.getDefaultProperties(mergedProperties);
        // This performs a deep clone on the properties object so that any array references can be removed
        this.properties = cloneDeep(mergedProperties);
    };
    // that is actually unused here and only used in overwritten methods in the components
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Pattern.prototype.didReceiveProps = function (newProperties, oldProperties) { };
    /**
     * This method is the best place to get elements from the DOM
     * and to initialize child components.
     * It is called directly after `writeDom` and directly before `readDom`.
     * @param isWriteDom A boolean indicating if the method call
     * is from after `writeDom` or from before `readDom`.
     * @param business The components business object.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Pattern.prototype.initDom = function (isWriteDom, business) { };
    /**
     * Initialization of events. Is called once after initialization
     * and then after each update causing a `writeDom`.
     * @param isInitial A boolean indicating if this is the initial call of `initEvents`.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Pattern.prototype.initEvents = function (isInitial) { };
    /**
     * Render the template partially.
     * This returns a string array with the innerHTML of each matched element.
     * @param template The template function
     * @param properties The properties to render the template with
     * @param selector The CSS selector to match elements and return their innerHTML
     */
    Pattern.prototype.renderPartially = function (template, properties, selector) {
        var html = template(properties);
        var doc = (new DOMParser()).parseFromString(html, 'text/html');
        var elements = Array.from(doc.querySelectorAll(selector));
        var htmlSnippets = elements.map(function (element) { return element.innerHTML; });
        return htmlSnippets;
    };
    return Pattern;
}());
export { Pattern };
/**
 * Given an element, this function checks the elements' tagname. If element is a heading, element is returned.
 * Otherwise it checks, if element has exactly one child element which is a heading.
 * This procedure is done recursively, until a heading element was found or the end of the DOM tree was reached.
 * @param element The element candidate
 * @returns The heading or null
 */
var getHeadingRecursively = function (element) {
    if (!element) {
        return null;
    }
    if (/H\d/.test(element.tagName)) {
        return element;
    }
    if (element.children.length !== 1) {
        return null;
    }
    return getHeadingRecursively(element.firstElementChild);
};
