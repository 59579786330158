/**
 * Base class name
 */
export var TABLE_BASE_CLASSNAME = 'ws10-table';
/**
 * Table class name
 */
export var TABLE_TABLE_CLASSNAME = "".concat(TABLE_BASE_CLASSNAME, "__table");
/**
 * Row class name
 */
export var TABLE_ROW_CLASSNAME = "".concat(TABLE_BASE_CLASSNAME, "__row");
/**
 * Cell class name
 */
export var TABLE_CELL_CLASSNAME = "".concat(TABLE_BASE_CLASSNAME, "__cell");
/**
 * Header class name
 */
export var TABLE_HEADER_CLASSNAME = "".concat(TABLE_CELL_CLASSNAME, "--header");
