var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import renderTemplate from './_promotional-card.hbs';
import './promotional-card.scss';
import { NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { PROMOTIONAL_CARD_CLASSNAME, PROMOTIONAL_CARD_CONFIRMATION_TEXT_CLASSNAME, PROMOTIONAL_CARD_CONFIRMATION_TEXT_SHOW_CLASSNAME, PROMOTIONAL_CARD_FLAG_BADGE_CLASSNAME, PROMOTIONAL_CARD_ICON_CLASSNAME, PROMOTIONAL_CARD_SUBLABEL_CLASSNAME, PROMOTIONAL_CARD_FORM_SELECTION_CONTROL_CONTAINER, OptVariant, PROMOTIONAL_CARD_HEADLINE, DEFAULT_OPT_TAG, } from './Constants';
import { createFlagBadge, getFlagBadgeColor, } from '@vfde-brix/ws10/flag-badge';
import { createFormSelectionControl, } from '@vfde-brix/ws10/form-selection-control';
import { getSystemIconName } from '@vfde-brix/ws10/system-icon';
/**
 * Promotional Card
 */
var PromotionalCard = /** @class */ (function (_super) {
    __extends(PromotionalCard, _super);
    function PromotionalCard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    PromotionalCard.prototype.getDefaultProperties = function (newProperties) {
        if (!newProperties.optVariant) {
            newProperties.optVariant = OptVariant.Standalone;
        }
        if (!newProperties.optTag) {
            newProperties.optTag = DEFAULT_OPT_TAG;
        }
        return newProperties;
    };
    /**
     * This helper function to initializing InputComponent on writeDom and for readDom
     *
     * First it gets the properties from other helper functions
     * then call the create function with the properties.
     */
    PromotionalCard.prototype.initDomElements = function () {
        var _this = this;
        this.flagBadgeContainerElement = this.containerElement
            .getElementsByClassName(PROMOTIONAL_CARD_FLAG_BADGE_CLASSNAME)[0];
        this.formSelectionControlContainerElement = this.containerElement
            .getElementsByClassName(PROMOTIONAL_CARD_FORM_SELECTION_CONTROL_CONTAINER)[0];
        if (this.formSelectionControlContainerElement) {
            var formSelectionControlProps = this.getFormSelectionControlProperties();
            this.formSelectionControlComponent = createFormSelectionControl(this.formSelectionControlContainerElement, 
            // if there are properties already, it is writeDom, otherwise readDom
            formSelectionControlProps || {
                onChange: function () {
                    _this.handleformSelectionControlChange();
                },
            });
        }
        if (this.flagBadgeContainerElement) {
            this.flagBadgeComponent = createFlagBadge(this.flagBadgeContainerElement, 
            // if there are properties already, it is writeDom, otherwise readDom
            this.properties ? this.properties.containerFlagBadge : NO_PATTERN_BUSINESS_LOGIC);
            var cardElement = this.containerElement.getElementsByClassName(PROMOTIONAL_CARD_CLASSNAME)[0];
            cardElement.style.borderColor = getFlagBadgeColor(this.flagBadgeContainerElement);
        }
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    PromotionalCard.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.initDomElements();
    };
    /**
     * This is an abstract function so you have to implement it.
     */
    PromotionalCard.prototype.readDom = function (promotionalCardBusinessLogic) {
        var _a, _b, _c;
        this.initDomElements();
        var stdSystemIconName = getSystemIconName(this.containerElement.getElementsByClassName(PROMOTIONAL_CARD_ICON_CLASSNAME)[0]);
        var txtSublabel = this.containerElement
            .getElementsByClassName(PROMOTIONAL_CARD_SUBLABEL_CLASSNAME)[0]
            .innerHTML.replace(/\s+/g, ' ')
            .trim();
        var stdConfirmationText = (_a = this.containerElement
            .getElementsByClassName(PROMOTIONAL_CARD_CONFIRMATION_TEXT_CLASSNAME)[0]) === null || _a === void 0 ? void 0 : _a.innerHTML.trim();
        var containerFormSelectionControl = (_b = this.formSelectionControlComponent) === null || _b === void 0 ? void 0 : _b.getProperties();
        var containerFlagBadge = (_c = this.flagBadgeComponent) === null || _c === void 0 ? void 0 : _c.getProperties();
        var headlineElement = this.containerElement.getElementsByClassName(PROMOTIONAL_CARD_HEADLINE)[0];
        var optTag = headlineElement.tagName.toLowerCase();
        var stdLabel = headlineElement.innerHTML.trim();
        return removeUndefinedFieldsFromObject({
            optTag: optTag,
            stdLabel: stdLabel,
            stdSystemIconName: stdSystemIconName,
            txtSublabel: txtSublabel,
            containerFormSelectionControl: containerFormSelectionControl,
            stdConfirmationText: stdConfirmationText,
            containerFlagBadge: containerFlagBadge,
            business: promotionalCardBusinessLogic,
        });
    };
    /**
     * Event to handle formSelectionControl text visibility if formSelectionControl changes
     */
    PromotionalCard.prototype.handleformSelectionControlChange = function () {
        var formSelectionControlCheckbox = this.formSelectionControlContainerElement.getElementsByTagName('input')[0];
        var confirmationTextElement = this.containerElement
            .getElementsByClassName(PROMOTIONAL_CARD_CONFIRMATION_TEXT_CLASSNAME)[0];
        confirmationTextElement.classList.toggle(PROMOTIONAL_CARD_CONFIRMATION_TEXT_SHOW_CLASSNAME, formSelectionControlCheckbox.checked);
    };
    /**
     * get form selection control parameters from DOM
     */
    PromotionalCard.prototype.getFormSelectionControlProperties = function () {
        var _this = this;
        var _a;
        if ((_a = this.properties) === null || _a === void 0 ? void 0 : _a.containerFormSelectionControl) {
            var onChange = function (event, value) {
                var _a, _b;
                (_b = (_a = _this.properties.business) === null || _a === void 0 ? void 0 : _a.onChange) === null || _b === void 0 ? void 0 : _b.call(_a, event, value);
                _this.handleformSelectionControlChange();
            };
            return __assign(__assign({}, this.properties.containerFormSelectionControl), { business: {
                    onChange: onChange,
                } });
        }
        return undefined;
    };
    return PromotionalCard;
}(Pattern));
export { PromotionalCard };
/**
 * This function returns an instance of PromotionalCard
 */
export var createPromotionalCard = function (containerElement, businessLogicOrProperties) {
    var promotionalCard = new PromotionalCard(containerElement, businessLogicOrProperties);
    promotionalCard.init();
    return promotionalCard;
};
