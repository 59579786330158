var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * Overlay component
 */
import './overlay.scss';
import renderTemplate from './_overlay.hbs';
import { getPropertiesFromClassName, getTransitionDurationFromElement, NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { createButtonIconOnly, } from '@vfde-brix/ws10/button-icon-only';
import { OVERLAY_CLASSNAME_ANIMATION_FADE, OVERLAY_CLASSNAME_BACKDROP, OVERLAY_CLASSNAME_NO_SCROLL, OVERLAY_CLASSNAME_SHOW, OVERLAY_ALIGNMENT, OVERLAY_ALIGNMENT_LEFT, OVERLAY_ANIMATIONS, OVERLAY_BASE_CLASSNAME, OVERLAY_CLOSE_BUTTON_CONTAINER_CLASSNAME, OVERLAY_CONTENT_CLASSNAME, OVERLAY_SPACING_CLASSNAME, OVERLAY_STAY_OPEN_CLASSNAME, OVERLAY_STATE_CLOSED, OVERLAY_STATE_OPEN, } from './Constants';
/**
 * Class Overlay
 */
var Overlay = /** @class */ (function (_super) {
    __extends(Overlay, _super);
    function Overlay() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _this._state = OVERLAY_STATE_CLOSED;
        return _this;
    }
    Object.defineProperty(Overlay.prototype, "state", {
        /**
         * Get the actual state of the overlay
         */
        get: function () {
            return this._state;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Get properties of close button
     */
    Overlay.getCloseButtonProperties = function () {
        return {
            stdIconName: 'close',
            stdAriaLabel: 'Schließen-Schaltfläche',
            optColor: 'tertiary',
            optShape: 'floating',
            optSize: 'standard',
            business: NO_PATTERN_BUSINESS_LOGIC,
        };
    };
    /**
     * Returns overlay backdrop element and creates it if it doesn't exist
     */
    Overlay.getOverlayBackdropElement = function () {
        var backdrop = document.body.getElementsByClassName(OVERLAY_CLASSNAME_BACKDROP)[0];
        if (backdrop === undefined) {
            backdrop = this.createBackdrop();
        }
        return backdrop;
    };
    /**
     * Creates the backdrop element
     */
    Overlay.createBackdrop = function () {
        var overlayBackdrop = document.createElement('div');
        overlayBackdrop.classList.add(OVERLAY_CLASSNAME_BACKDROP, OVERLAY_CLASSNAME_ANIMATION_FADE);
        return overlayBackdrop;
    };
    /**
     * Toggles the overlay
     */
    Overlay.prototype.toggle = function () {
        this.state === OVERLAY_STATE_OPEN ? this.close() : this.open();
    };
    /**
     * Opens the overlay by appending a class
     */
    Overlay.prototype.open = function () {
        var _this = this;
        this.focusedElementBeforeOverlay = document.activeElement;
        var overlayBackdrop = Overlay.getOverlayBackdropElement();
        document.body.appendChild(overlayBackdrop);
        // Force a reflow for animation
        overlayBackdrop.offsetWidth;
        var backdropTransition = getTransitionDurationFromElement(overlayBackdrop);
        this.overlayElement.style.display = 'block';
        overlayBackdrop.style.display = 'block';
        // Force a reflow for animation
        this.overlayElement.offsetWidth;
        var overlayTransition = getTransitionDurationFromElement(this.overlayElement);
        // Fade backdrop and overlay by adding class "show"
        overlayBackdrop.classList.add(OVERLAY_CLASSNAME_SHOW);
        this.overlayElement.classList.add(OVERLAY_CLASSNAME_SHOW);
        // Set scroll position of overlay content back to top (CO-13745)
        this.getOverlayContentContainer().scroll(0, 0);
        this.initOverlayEvents();
        // Wait for animation to finish before setting state to opened and executing onOpen callback
        setTimeout(function () {
            _this._state = OVERLAY_STATE_OPEN;
            // Save scroll position (because we are going to prevent the body from scrolling)
            _this._scrollPosition = window.scrollY;
            document.body.classList.add(OVERLAY_CLASSNAME_NO_SCROLL);
            // Execute open callback if defined
            if (_this.properties.business.onOpen !== undefined) {
                _this.properties.business.onOpen(_this);
            }
            var focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
            // All focusable elements inside the overlay, including the close button
            _this.focusableElements = Array.from(_this.overlayElement.querySelectorAll(focusableElements));
            // Set the focus to the first focusable element inside the overlay
            if (_this.properties.optAutoFocus) {
                var contentContainerElement = _this.getOverlayContentContainer();
                var optFirstFocusableElement = contentContainerElement
                    .querySelector(_this.properties.optFirstFocusableElement);
                var elementToFocus = optFirstFocusableElement || _this.focusableElements[_this.firstFocusableElementId];
                elementToFocus.focus();
            }
        }, Math.max(backdropTransition, overlayTransition));
    };
    /**
     * Closes the overlay by removing a class
     */
    Overlay.prototype.close = function () {
        var _this = this;
        var overlayBackdrop = Overlay.getOverlayBackdropElement();
        var transitionTime = getTransitionDurationFromElement(this.overlayElement);
        this.overlayElement.classList.remove(OVERLAY_CLASSNAME_SHOW);
        overlayBackdrop.classList.remove(OVERLAY_CLASSNAME_SHOW);
        this.removeOverlayEvents();
        // Wait for animation to finish before removing and hiding elements
        setTimeout(function () {
            document.body.classList.remove(OVERLAY_CLASSNAME_NO_SCROLL);
            // Restore scroll position
            window.scroll(0, _this._scrollPosition);
            // Hide overlay and background
            _this.overlayElement.style.display = 'none';
            overlayBackdrop.style.display = 'none';
            _this._state = OVERLAY_STATE_CLOSED;
            // Execute close callback if defined
            if (_this.properties.business.onClose !== undefined) {
                _this.properties.business.onClose(_this);
            }
        }, transitionTime);
        // Give the focus back to the element that was focused before opening the overlay
        this.focusedElementBeforeOverlay.focus();
    };
    /**
     * Injects any content into the overlay
     * @todo return promise? or callback after injection? so that the overlay does not open before content is injected
     */
    Overlay.prototype.injectContent = function () {
        var content = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            content[_i] = arguments[_i];
        }
        var container = this.getOverlayContentContainer();
        switch (typeof content[0]) {
            case 'string':
                container.innerHTML = content.join('');
                break;
            case 'object':
                container.innerHTML = '';
                container.append.apply(container, __spreadArray([], __read(content), false));
                break;
        }
    };
    /**
     * Returns the overlay content container element
     */
    Overlay.prototype.getOverlayContentContainer = function () {
        return this.overlayElement.getElementsByClassName(OVERLAY_CONTENT_CLASSNAME)[0];
    };
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    Overlay.prototype.getDefaultProperties = function (newProperties) {
        if (newProperties.optContentAlign === undefined) {
            newProperties.optContentAlign = OVERLAY_ALIGNMENT_LEFT;
        }
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    Overlay.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.overlayElement = this.containerElement.getElementsByClassName(OVERLAY_BASE_CLASSNAME)[0];
        this.closeButtonContainerElement = this.containerElement
            .getElementsByClassName(OVERLAY_CLOSE_BUTTON_CONTAINER_CLASSNAME)[0];
        createButtonIconOnly(this.closeButtonContainerElement, Overlay.getCloseButtonProperties());
    };
    Object.defineProperty(Overlay.prototype, "isFocusInsideTheOverlay", {
        /**
         * Check if the focus is inside the overlay
         */
        get: function () {
            return this.containerElement.contains(document.activeElement);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Overlay.prototype, "firstFocusableElementId", {
        /**
         * Check if there are other interactive components than the close button, if so we want to focus that first
         */
        get: function () {
            return this.isFocusInsideTheOverlay ? 0 : this.focusableElements[1] ? 1 : 0;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Handle keydown events
     */
    Overlay.prototype.handleKeyDown = function (event) {
        if (event.code === 'Escape') {
            this.close();
        }
        // Keep the focus inside the overlay: if the focus has reached to last focusable element then focus the first focusable element
        if (event.key === 'Tab') {
            var isFocusInsideTheOverlay = this.isFocusInsideTheOverlay;
            var firstFocusableElement = this.focusableElements[this.firstFocusableElementId];
            var lastFocusableElement = this.focusableElements[this.focusableElements.length - 1];
            var isFirstElementFocused = document.activeElement === firstFocusableElement;
            var isLastElementFocused = document.activeElement === lastFocusableElement;
            if (!event.shiftKey) {
                // Tab pressed
                // If the focus is outside the overlay, we start the focus iteration from the `optFirstFocusableElement` (if there's one)
                if (this.properties.optFirstFocusableElement && !isFocusInsideTheOverlay) {
                    var contentContainerElement = this.getOverlayContentContainer();
                    var optFirstFocusableElement = contentContainerElement
                        .querySelectorAll(this.properties.optFirstFocusableElement)[0];
                    var elementToFocus = optFirstFocusableElement || firstFocusableElement;
                    elementToFocus.focus();
                    event.preventDefault();
                }
                else if (isLastElementFocused || !isFocusInsideTheOverlay) {
                    firstFocusableElement.focus();
                    event.preventDefault();
                }
            }
            else if (isFirstElementFocused || !isFocusInsideTheOverlay) {
                // Shift + Tab pressed
                lastFocusableElement.focus();
                event.preventDefault();
            }
        }
    };
    /**
     * Handle outside click
     */
    Overlay.prototype.handleClick = function (event) {
        if (this.properties.optStayOpenOnClickOutside) {
            return;
        }
        var clickedElement = event.target;
        if (clickedElement === this.overlayElement) {
            event.stopPropagation();
            this.close();
        }
    };
    /**
     * Add event listeners that should work only when the overlay is opened
     * @protected
     */
    Overlay.prototype.initOverlayEvents = function () {
        // Bind key event handler
        this.bindedKeyEventHandler = this.handleKeyDown.bind(this);
        document.addEventListener('keydown', this.bindedKeyEventHandler);
        // Bind close btn handler
        this.bindedCloseBtnHandler = this.close.bind(this);
        this.closeButtonContainerElement.firstElementChild.addEventListener('click', this.bindedCloseBtnHandler);
        // Bind outside click handler
        this.bindedClickEventHandler = this.handleClick.bind(this);
        document.addEventListener('click', this.bindedClickEventHandler);
    };
    /**
     * Remove event listeners that should work only when the overlay is opened
     * @protected
     */
    Overlay.prototype.removeOverlayEvents = function () {
        // Remove key event handlers
        document.removeEventListener('keydown', this.bindedKeyEventHandler);
        // Remove close btn event handler
        this.closeButtonContainerElement.firstElementChild.removeEventListener('click', this.bindedCloseBtnHandler);
        // Remove outside click event handler
        document.removeEventListener('click', this.bindedClickEventHandler);
    };
    /**
     * Get overlay properties from DOM
     */
    Overlay.prototype.readDom = function (business) {
        this.overlayElement = this.containerElement.getElementsByClassName(OVERLAY_BASE_CLASSNAME)[0];
        this.closeButtonContainerElement = this.overlayElement
            .getElementsByClassName(OVERLAY_CLOSE_BUTTON_CONTAINER_CLASSNAME)[0];
        var _a = getPropertiesFromClassName(this.overlayElement.className, {
            optAnimation: OVERLAY_ANIMATIONS,
            optContentAlign: OVERLAY_ALIGNMENT.map(function (variation) { return "align-".concat(variation); }),
        }), optAnimation = _a.optAnimation, optContentAlign = _a.optContentAlign;
        var optStayOpenOnClickOutside = this.overlayElement.classList.contains(OVERLAY_STAY_OPEN_CLASSNAME);
        var optPaddingLayout = this.overlayElement.classList.contains(OVERLAY_SPACING_CLASSNAME);
        // Render close button
        createButtonIconOnly(this.closeButtonContainerElement, Overlay.getCloseButtonProperties());
        var properties = {
            business: business,
            optAnimation: optAnimation,
            optContentAlign: (optContentAlign === null || optContentAlign === void 0 ? void 0 : optContentAlign.replace('align-', '')) || OVERLAY_ALIGNMENT_LEFT,
            optStayOpenOnClickOutside: optStayOpenOnClickOutside,
            optPaddingLayout: optPaddingLayout,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    return Overlay;
}(Pattern));
export { Overlay };
/**
 * This function returns an instance of Overlay
 */
export var createOverlay = function (containerElement, businessLogicOrProperties) {
    var overlay = new Overlay(containerElement, businessLogicOrProperties);
    overlay.init();
    return overlay;
};
