var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { attempt } from '../utils/attempt';
/**
 * Default function used by createComponents to retrieve the container element
 * (parentElement of the component element)
 */
var defaultGetContainerElement = function (element) { return element.parentElement; };
/**
 * Creates components for each element found by the given selector
 * @param selector The components CSS selector
 * @param factory The components factory function
 * @param businessLogicOrProperties The businesslogic or properties object
 * @param context The context element to search in (default = document)
 * @param getContainerElement An optional function which can be used to define,
 * which element is used as container element for the components.
 * If omitted, the parentElement of each element will be used as container.
 */
export var createComponents = function (selector, factory, businessLogicOrProperties, context, getContainerElement) {
    var e_1, _a;
    if (context === void 0) { context = document; }
    if (getContainerElement === void 0) { getContainerElement = defaultGetContainerElement; }
    var elements = context.querySelectorAll(selector);
    var components = [];
    try {
        for (var elements_1 = __values(elements), elements_1_1 = elements_1.next(); !elements_1_1.done; elements_1_1 = elements_1.next()) {
            var element = elements_1_1.value;
            var component = createComponent(factory, getContainerElement(element), businessLogicOrProperties);
            component && components.push(component);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (elements_1_1 && !elements_1_1.done && (_a = elements_1.return)) _a.call(elements_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return components;
};
/**
 * Receives the component's factory function, the containerElement and businessLogicOrProperties
 * and wraps the factory call in a try-catch so components don't influence each other
 * @param factory The components factory function
 * @param containerElement The container element
 * @param businessLogicOrProperties The businesslogic or properties object
 */
export var createComponent = function (factory, containerElement, businessLogicOrProperties) {
    return attempt(function () { return factory(containerElement, businessLogicOrProperties); }, undefined, function (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to create component', containerElement, error);
        return null;
    });
};
/**
 * Receives the component's factory function, the ID of a containerElement and businessLogicOrProperties
 * and wraps the factory call in a try-catch so components don't influence each other
 * @param factory The components factory function
 * @param elementId The ID of a container element
 * @param businessLogicOrProperties The businesslogic or properties object
 */
export var createComponentById = function (factory, elementId, businessLogicOrProperties) {
    var element = document.getElementById(elementId);
    return element && createComponent(factory, element, businessLogicOrProperties);
};
