/**
 * The possible Promo-Price Sizes
 */
export var PromoPriceVariant;
(function (PromoPriceVariant) {
    PromoPriceVariant["Small"] = "small";
    PromoPriceVariant["Medium"] = "medium";
    PromoPriceVariant["Large"] = "large";
})(PromoPriceVariant || (PromoPriceVariant = {}));
/**
 * Size Variation small
 */
export var PROMO_PRICE_SIZE_SMALL = PromoPriceVariant.Small;
/**
 * Size Variation medium
 */
export var PROMO_PRICE_SIZE_MEDIUM = PromoPriceVariant.Medium;
/**
 * Size Variation large
 */
export var PROMO_PRICE_SIZE_LARGE = PromoPriceVariant.Large;
/**
 * Size Variations
 */
export var PROMO_PRICE_SIZES = [
    PROMO_PRICE_SIZE_SMALL,
    PROMO_PRICE_SIZE_MEDIUM,
    PROMO_PRICE_SIZE_LARGE,
];
export var PROMO_PRICE_BASE_CLASSNAME = 'ws10-promo-price';
export var PROMO_PRICE_SUM_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "__sum");
export var PROMO_PRICE_DECIMAL_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "__decimal");
export var PROMO_PRICE_STRIKE_PRICE_VALUE_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "__strike-price-value");
export var PROMO_PRICE_CONDITION_BEFORE_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "__condition-before");
export var PROMO_PRICE_CONDITION_AFTER_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "__condition-after");
export var PROMO_PRICE_INVERSE_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "--inverse");
export var PROMO_PRICE_INTEGER_CLASSNAME = "".concat(PROMO_PRICE_BASE_CLASSNAME, "--integer");
