var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " ws10-form-selection-control__error";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"ws10-form-selection-control__item ws10-form-selection-control__item--"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"optType") : depths[1]), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"optVariant") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":105},"end":{"line":3,"column":186}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"spacing") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":186},"end":{"line":3,"column":233}}})) != null ? stack1 : "")
    + "\">\n            <input id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"stdID") || (depth0 != null ? lookupProperty(depth0,"stdID") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"stdID","hash":{},"data":data,"loc":{"start":{"line":4,"column":23},"end":{"line":4,"column":32}}}) : helper)))
    + "\" class=\"ws10-form-selection-control__input\" name=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"stdGroupName") : depths[1]), depth0))
    + "\" type=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"optType") : depths[1]), depth0))
    + "\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"stdValue") || (depth0 != null ? lookupProperty(depth0,"stdValue") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"stdValue","hash":{},"data":data,"loc":{"start":{"line":4,"column":135},"end":{"line":4,"column":147}}}) : helper)))
    + "\" "
    + alias2(((helper = (helper = lookupProperty(helpers,"optState") || (depth0 != null ? lookupProperty(depth0,"optState") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"optState","hash":{},"data":data,"loc":{"start":{"line":4,"column":149},"end":{"line":4,"column":161}}}) : helper)))
    + " />\n            <label for=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"stdID") || (depth0 != null ? lookupProperty(depth0,"stdID") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"stdID","hash":{},"data":data,"loc":{"start":{"line":5,"column":24},"end":{"line":5,"column":33}}}) : helper)))
    + "\" class=\"ws10-form-selection-control__label\">\n                <span class=\"ws10-form-selection-control__text\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtLabelText") || (depth0 != null ? lookupProperty(depth0,"txtLabelText") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"txtLabelText","hash":{},"data":data,"loc":{"start":{"line":6,"column":64},"end":{"line":6,"column":82}}}) : helper))) != null ? stack1 : "")
    + "</span>\n            </label>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-form-selection-control__item--"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"optVariant") : depths[1]), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-spacing-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"spacing") || (depth0 != null ? lookupProperty(depth0,"spacing") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"spacing","hash":{},"data":data,"loc":{"start":{"line":3,"column":215},"end":{"line":3,"column":226}}}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    return " ws10-is-hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-form-selection-control"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasError") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":39},"end":{"line":1,"column":98}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":9,"column":13}}})) != null ? stack1 : "")
    + "    <span tabindex=\"0\" class=\"ws10-form-selection-control__error-message"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasError") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":72},"end":{"line":10,"column":118}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"errorMessage") || (depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"errorMessage","hash":{},"data":data,"loc":{"start":{"line":10,"column":120},"end":{"line":10,"column":138}}}) : helper))) != null ? stack1 : "")
    + "</span>\n</div>\n";
},"useData":true,"useDepths":true});