var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import './promo-price.scss';
import renderTemplate from './_promo-price.hbs';
import { HandlebarRenderer, removeUndefinedFieldsFromObject, getPropertiesFromClassName, Pattern, Price, } from '@vfde-brix/ws10/core';
import { PROMO_PRICE_BASE_CLASSNAME, PROMO_PRICE_DECIMAL_CLASSNAME, PROMO_PRICE_SUM_CLASSNAME, PROMO_PRICE_CONDITION_BEFORE_CLASSNAME, PROMO_PRICE_SIZES, PROMO_PRICE_STRIKE_PRICE_VALUE_CLASSNAME, PROMO_PRICE_CONDITION_AFTER_CLASSNAME, PROMO_PRICE_INVERSE_CLASSNAME, PromoPriceVariant, } from './Constants';
/**
 * The PromoPrice component displays a promo-price value, striked promo-price, has
 * size and versions variations. PromoPrices can be updated.
 * @todo Pertz -> In brix view dynamic background
 */
var PromoPrice = /** @class */ (function (_super) {
    __extends(PromoPrice, _super);
    function PromoPrice() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    PromoPrice.prototype.getDefaultProperties = function (newProperties) {
        if (newProperties.optSizeVariation === undefined) {
            newProperties.optSizeVariation = PromoPriceVariant.Large;
        }
        if (newProperties.optShowDecimalsAlways === undefined) {
            newProperties.optShowDecimalsAlways = false;
        }
        return newProperties;
    };
    /**
     * Render this component to the DOM
     */
    PromoPrice.prototype.writeDom = function () {
        HandlebarRenderer.render(this.containerElement, renderTemplate(this.properties));
    };
    /**
     * Get properties from DOM
     */
    PromoPrice.prototype.readDom = function (patternWithNoPatternBusinessLogic) {
        var promoPriceElement = this.containerElement.getElementsByClassName(PROMO_PRICE_BASE_CLASSNAME)[0];
        var euro = promoPriceElement.getElementsByClassName(PROMO_PRICE_SUM_CLASSNAME)[0].textContent.trim();
        var centElement = promoPriceElement.getElementsByClassName(PROMO_PRICE_DECIMAL_CLASSNAME);
        var cent = '0';
        var optShowDecimalsAlways;
        if (centElement.length > 0) {
            cent = centElement[0].textContent.trim();
            if (cent === '00') {
                optShowDecimalsAlways = true;
            }
        }
        var strikePriceContainer = promoPriceElement.getElementsByClassName(PROMO_PRICE_STRIKE_PRICE_VALUE_CLASSNAME)[0];
        var strikePrice;
        if (strikePriceContainer) {
            var strikePriceContent = strikePriceContainer.textContent.trim();
            strikePrice = Price.fromString(strikePriceContent);
        }
        var sizeVariation = getPropertiesFromClassName(promoPriceElement.className, {
            optSizeVariation: PROMO_PRICE_SIZES,
        });
        var stdConditionAfterContainer = promoPriceElement.getElementsByClassName(PROMO_PRICE_CONDITION_AFTER_CLASSNAME);
        var stdConditionAfter;
        if (stdConditionAfterContainer.length > 0) {
            stdConditionAfter = stdConditionAfterContainer[0].innerHTML.trim();
        }
        var priceConditionELement = promoPriceElement.getElementsByClassName(PROMO_PRICE_CONDITION_BEFORE_CLASSNAME);
        var priceConditionText;
        if (priceConditionELement.length > 0) {
            priceConditionText = priceConditionELement[0].textContent.trim();
        }
        var optInverse = promoPriceElement.classList.contains(PROMO_PRICE_INVERSE_CLASSNAME);
        var optAriaLive;
        if (promoPriceElement.hasAttribute('aria-live')) {
            optAriaLive = promoPriceElement.getAttribute('aria-live');
        }
        var properties = __assign(__assign({}, sizeVariation), { price: new Price(euro, cent), strikePrice: strikePrice, stdConditionAfter: stdConditionAfter, stdStrikePriceText: promoPriceElement.dataset.strikePriceText, stdOfferPriceText: promoPriceElement.dataset.offerPriceText, optPriceConditionBefore: priceConditionText, optShowDecimalsAlways: optShowDecimalsAlways, optInverse: optInverse, optAriaLive: optAriaLive, business: patternWithNoPatternBusinessLogic });
        return removeUndefinedFieldsFromObject(properties);
    };
    /**
     * Get promoPrice properties from DOM
     */
    PromoPrice.getPropertiesFromDom = function (containerElement) {
        return Pattern.getPropsFromDom(containerElement, createPromoPrice);
    };
    return PromoPrice;
}(Pattern));
export { PromoPrice };
/**
 * Creates PromoPrice component, takes container element and properties
 */
export var createPromoPrice = function (containerElement, businessLogicOrProperties) {
    var promoPrice = new PromoPrice(containerElement, businessLogicOrProperties);
    promoPrice.init();
    return promoPrice;
};
