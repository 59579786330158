var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " js-open-individually";
},"3":function(container,depth0,helpers,partials,data) {
    return " js-jump-to-clicked-item";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div tabindex=\"0\" class=\"ws10-accordion-item__tab"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optHidden") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":53},"end":{"line":3,"column":97}}})) != null ? stack1 : "")
    + "\">\n        <div tabindex=\"-1\" class=\"ws10-accordion-item__tab-content ws10-accordion-item ws10-accordion-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"stdId") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optOpen") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":116},"end":{"line":4,"column":171}}})) != null ? stack1 : "")
    + "\">\n            <input type=\"checkbox\" id=\"ws10-accordion-input-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"stdId") : depth0), depth0))
    + "\" class=\"ws10-accordion-item__checkbox ws10-is-hidden\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optOpen") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":128},"end":{"line":5,"column":163}}})) != null ? stack1 : "")
    + " />\n            <label class=\"ws10-accordion-item__label\" for=\"ws10-accordion-input-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"stdId") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdIconLeft") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + "                <span class=\"ws10-accordion-item__headline\">\n                    "
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"stdHeadline") : depth0), depth0)) != null ? stack1 : "")
    + "\n                </span>\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-primary-200","optSize":"size-200","stdName":"chevron-down","stdClassName":"ws10-accordion-item__chevron"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <div class=\"ws10-accordion-item__content ws10-slide\" id=\"ws10-accordion-item__content-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"stdId") : depth0), depth0))
    + "\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"optOpen") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":113},"end":{"line":15,"column":167}}})) != null ? stack1 : "")
    + ">\n                <div class=\"ws10-accordion-item__content-box\">\n                    "
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"containerAnyComponent") : depth0), depth0)) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " ws10-is-hidden";
},"8":function(container,depth0,helpers,partials,data) {
    return " ws10-accordion-item--opened";
},"10":function(container,depth0,helpers,partials,data) {
    return " checked";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optSize":"size-200","stdName":(depth0 != null ? lookupProperty(depth0,"stdIconLeft") : depth0),"stdClassName":"ws10-accordion-item__icon ws10-accordion-item__icon--left ws10-icon js-css-icon"},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return " aria-hidden=\"true\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-accordion-deeplink=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"optDeepLink") : depth0), depth0))
    + "\" data-accordion-variant=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"optVariant") : depth0), depth0))
    + "\" class=\"ws10-accordion"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"optOpenIndividually") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":178}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"optJumpToClickedItem") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":178},"end":{"line":1,"column":242}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});