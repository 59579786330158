var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import './link-tiles.scss';
import { getPropertiesFromClassName, NO_PATTERN_BUSINESS_LOGIC, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { columnNumbers, LINK_TILES_BASE_CLASSNAME, LINK_TILES_HIGHLIGHT_BADGE_CLASSNAME, LINK_TILES_ITEM_CLASSNAME, LINK_TILES_LINK_LABEL_CLASSNAME, LINK_TILES_LIST_CLASSNAME, LINK_TILES_LINK_CLASSNAME, LINK_TILES_ITEM_DISABLED_CLASSNAME, } from './Constants';
import renderTemplate from './_link-tiles.hbs';
import { createIcon, } from '@vfde-brix/ws10/icon';
import { HighlightBadge } from '@vfde-brix/ws10/highlight-badge';
/**
 * Link Tiles
 */
var LinkTiles = /** @class */ (function (_super) {
    __extends(LinkTiles, _super);
    function LinkTiles() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.linkTilesContainer = _this.containerElement.getElementsByClassName(LINK_TILES_BASE_CLASSNAME)[0];
        _this.linkTilesItemElements = __spreadArray([], __read(_this.containerElement.getElementsByClassName(LINK_TILES_ITEM_CLASSNAME)), false);
        return _this;
    }
    LinkTiles.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    LinkTiles.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /* istanbul ignore next */
    LinkTiles.prototype.initEvents = function (isInitial) {
        var _this = this;
        if (isInitial) {
            this.properties.business.onClick && this.containerElement.addEventListener('click', function (event) {
                // In case of business logic, we have to stop the browser from following the link. The event handler can redirect to it if necessary.
                event.preventDefault();
                var anchor = event.target.closest(".".concat(LINK_TILES_LINK_CLASSNAME));
                if (anchor) {
                    _this.properties.business.onClick(event, anchor.dataset.id || null, (anchor === null || anchor === void 0 ? void 0 : anchor.href) || null);
                }
            });
        }
    };
    LinkTiles.prototype.readDom = function (linkTilesBusinessLogic) {
        var e_1, _a;
        var _b;
        this.linkTilesContainer = this.containerElement.getElementsByClassName(LINK_TILES_BASE_CLASSNAME)[0];
        var listContainer = this.linkTilesContainer.getElementsByClassName(LINK_TILES_LIST_CLASSNAME)[0];
        var tilesPerRow = getPropertiesFromClassName(listContainer.className, { optTilesPerRow: columnNumbers });
        var optTilesPerRow = removeUndefinedFieldsFromObject(tilesPerRow);
        var properties = __assign({ linkTilesItems: [], business: linkTilesBusinessLogic }, optTilesPerRow);
        this.linkTilesItemElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(LINK_TILES_ITEM_CLASSNAME)), false);
        try {
            for (var _c = __values(this.linkTilesItemElements), _d = _c.next(); !_d.done; _d = _c.next()) {
                var itemElement = _d.value;
                var _e = __read(itemElement.getElementsByClassName(LINK_TILES_HIGHLIGHT_BADGE_CLASSNAME), 1), highlightBadgeContainer = _e[0];
                var containerHighlightBadge = highlightBadgeContainer
                    && HighlightBadge.getPropertiesFromDom(highlightBadgeContainer);
                var _f = __read(itemElement.getElementsByClassName(LINK_TILES_LINK_CLASSNAME), 1), linkElement = _f[0];
                var id = linkElement.dataset.id || undefined;
                var linkHref = linkElement.getAttribute('href') || linkElement.getAttribute('data-href');
                var stdLabel = (_b = itemElement.getElementsByClassName(LINK_TILES_LINK_LABEL_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.innerHTML.trim();
                var optTarget = (linkElement.getAttribute('target') || linkElement.getAttribute('data-target'));
                var icon = createIcon(itemElement, NO_PATTERN_BUSINESS_LOGIC);
                var imgMidRenderIconPath = icon.getProperties().imgMidRenderIconPath;
                var optDisabled = itemElement.classList.contains(LINK_TILES_ITEM_DISABLED_CLASSNAME);
                properties.linkTilesItems.push(removeUndefinedFieldsFromObject(__assign(__assign(__assign(__assign({ id: id, containerHighlightBadge: containerHighlightBadge }, linkHref && { linkHref: linkHref }), { stdLabel: stdLabel }), optTarget && { optTarget: optTarget }), { imgMidRenderIconPath: imgMidRenderIconPath, optDisabled: optDisabled, business: NO_PATTERN_BUSINESS_LOGIC })));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return properties;
    };
    return LinkTiles;
}(Pattern));
export { LinkTiles };
/** This function returns an instance of LinkTiles */
export var createLinkTiles = function (containerElement, businessLogicOrProperties) {
    var linkTiles = new LinkTiles(containerElement, businessLogicOrProperties);
    linkTiles.init();
    return linkTiles;
};
