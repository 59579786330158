/**
 * Base class name
 */
export var BUTTON_ICON_ONLY_BASE_CLASSNAME = 'ws10-button-icon-only';
/**
 * Button Variation: primary
 */
var BUTTON_ICON_ONLY_COLOR_PRIMARY = 'primary';
/**
 * Button Variation: primary-inverse
 */
var BUTTON_ICON_ONLY_COLOR_PRIMARY_INVERSE = 'primary-inverse';
/**
 * Button Variation: secondary
 */
var BUTTON_ICON_ONLY_COLOR_SECONDARY = 'secondary';
/**
 * Button Variation: secondary-inverse
 */
var BUTTON_ICON_ONLY_COLOR_SECONDARY_INVERSE = 'secondary-inverse';
/**
 * Button Variation: tertiary
 */
var BUTTON_ICON_ONLY_COLOR_TERTIARY = 'tertiary';
/**
 * Button Variation: tertiary-inverse
 */
var BUTTON_ICON_ONLY_COLOR_TERTIARY_INVERSE = 'tertiary-inverse';
/**
 * Button variation: default square
 */
var BUTTON_ICON_ONLY_SHAPE_DEFAULT = 'square';
/**
 * Button variation: circle
 */
var BUTTON_ICON_ONLY_SHAPE_CIRCLE = 'circle';
/**
 * Button variation: floating
 */
var BUTTON_ICON_ONLY_SHAPE_FLOATING = 'floating';
/**
 * Button variation: transparent
 */
export var BUTTON_ICON_ONLY_SHAPE_TRANSPARENT = 'transparent';
/**
 * Button variation: default standard
 */
export var BUTTON_ICON_ONLY_SIZE_STANDARD = 'standard';
/**
 * Button variation: large
 */
export var BUTTON_ICON_ONLY_SIZE_LARGE = 'large';
/**
 * Color Variations
 */
export var BUTTON_ICON_ONLY_COLORS = [
    BUTTON_ICON_ONLY_COLOR_PRIMARY,
    BUTTON_ICON_ONLY_COLOR_PRIMARY_INVERSE,
    BUTTON_ICON_ONLY_COLOR_SECONDARY,
    BUTTON_ICON_ONLY_COLOR_SECONDARY_INVERSE,
    BUTTON_ICON_ONLY_COLOR_TERTIARY,
    BUTTON_ICON_ONLY_COLOR_TERTIARY_INVERSE,
];
/**
 * SHape variattions
 */
export var BUTTON_ICON_ONLY_SHAPES = [
    BUTTON_ICON_ONLY_SHAPE_DEFAULT,
    BUTTON_ICON_ONLY_SHAPE_CIRCLE,
    BUTTON_ICON_ONLY_SHAPE_FLOATING,
    BUTTON_ICON_ONLY_SHAPE_TRANSPARENT,
];
/**
 * Size variations
 */
export var BUTTON_ICON_ONLY_SIZES = [
    BUTTON_ICON_ONLY_SIZE_STANDARD,
    BUTTON_ICON_ONLY_SIZE_LARGE,
];
/**
 * Classname to check disabled button
 */
export var BUTTON_ICON_ONLY_DISABLED = "".concat(BUTTON_ICON_ONLY_BASE_CLASSNAME, "--disabled");
