var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "a";
},"3":function(container,depth0,helpers,partials,data) {
    return "button";
},"5":function(container,depth0,helpers,partials,data) {
    return " ws10-button-link--disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":5,"column":51}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkHref") || (depth0 != null ? lookupProperty(depth0,"linkHref") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkHref","hash":{},"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":22}}}) : helper)))
    + "\" target=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"optTarget") || (depth0 != null ? lookupProperty(depth0,"optTarget") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optTarget","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":45}}}) : helper)))
    + "\""
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optTarget") : depth0),"===","_blank",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":46},"end":{"line":5,"column":40}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "\n    rel=\"noopener noreferrer\"";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    data-href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkHref") || (depth0 != null ? lookupProperty(depth0,"linkHref") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkHref","hash":{},"data":data,"loc":{"start":{"line":7,"column":15},"end":{"line":7,"column":27}}}) : helper)))
    + "\"\n    data-target=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"optTarget") || (depth0 != null ? lookupProperty(depth0,"optTarget") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optTarget","hash":{},"data":data,"loc":{"start":{"line":8,"column":17},"end":{"line":8,"column":30}}}) : helper)))
    + "\"\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    type=\"button\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "    disabled=\"disabled\"\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "    aria-disabled=\"true\"\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    data-action=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"optAction") || (depth0 != null ? lookupProperty(depth0,"optAction") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optAction","hash":{},"data":data,"loc":{"start":{"line":20,"column":17},"end":{"line":20,"column":32}}}) : helper))) != null ? stack1 : "")
    + "\"\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-tracking-name=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdTrackingName") || (depth0 != null ? lookupProperty(depth0,"stdTrackingName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdTrackingName","hash":{},"data":data,"loc":{"start":{"line":22,"column":48},"end":{"line":22,"column":69}}}) : helper))) != null ? stack1 : "")
    + "\"";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":(depth0 != null ? lookupProperty(depth0,"optColorVariant") : depth0),"optSize":"size-150","stdName":(depth0 != null ? lookupProperty(depth0,"stdSystemIconName") : depth0),"stdClassName":"ws10-button-link__icon ws10-button-link__icon--left"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":(depth0 != null ? lookupProperty(depth0,"optColorVariant") : depth0),"optSize":"size-150","stdName":(depth0 != null ? lookupProperty(depth0,"stdSystemIconName") : depth0),"stdClassName":"ws10-button-link__icon ws10-button-link__icon--right"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkHref") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":1},"end":{"line":1,"column":39}}})) != null ? stack1 : "")
    + "\n    class=\"ws10-button-link ws10-button-link--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optColorVariant") || (depth0 != null ? lookupProperty(depth0,"optColorVariant") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optColorVariant","hash":{},"data":data,"loc":{"start":{"line":2,"column":46},"end":{"line":2,"column":65}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":65},"end":{"line":2,"column":118}}})) != null ? stack1 : "")
    + "\"\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkHref") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAction") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdTrackingName") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":22,"column":77}}})) != null ? stack1 : "")
    + "\n>\n\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optIconPosition") : depth0),"===","left",{"name":"ifCond","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "\n    <span class=\"ws10-button-link__text ws10-text ws10-spacing-0\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdLabel") || (depth0 != null ? lookupProperty(depth0,"stdLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdLabel","hash":{},"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":30,"column":24}}}) : helper))) != null ? stack1 : "")
    + "\n    </span>\n\n"
    + ((stack1 = __default(require("../../internal/handlebars/helper/webpack/ifCond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"optIconPosition") : depth0),"===","right",{"name":"ifCond","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":4},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "\n</"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkHref") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":37,"column":2},"end":{"line":37,"column":40}}})) != null ? stack1 : "")
    + ">\n\n";
},"usePartial":true,"useData":true});