import { NO_PATTERN_BUSINESS_LOGIC } from '@vfde-brix/ws10/core';
/**
 * Class base name
 */
export var CAROUSEL_BASE_CLASSNAME = 'ws10-carousel';
/**
 * carousel slider
 */
export var CAROUSEL_SLIDER_BASE_CLASSNAME = "".concat(CAROUSEL_BASE_CLASSNAME, "-slider");
/**
 * slider track
 */
export var CAROUSEL_SLIDER_TRACK = "".concat(CAROUSEL_SLIDER_BASE_CLASSNAME, "__track");
/**
 * slider list
 */
export var CAROUSEL_SLIDER_LIST = "".concat(CAROUSEL_SLIDER_BASE_CLASSNAME, "__list");
/**
 * Base class for carousel slider pagination pages container
 */
export var CAROUSEL_SLIDER_PAGINATION_BASE_CLASSNAME = "".concat(CAROUSEL_SLIDER_BASE_CLASSNAME, "__pagination");
/**
 * Base class for carousel slider pagination pages inverse container
 */
export var CAROUSEL_SLIDER_PAGINATION_PAGE_INVERSE = "".concat(CAROUSEL_SLIDER_PAGINATION_BASE_CLASSNAME, "__page--inverse");
/**
 * carousel slider pagination page
 */
export var CAROUSEL_SLIDER_PAGINATION_PAGE = "".concat(CAROUSEL_SLIDER_PAGINATION_BASE_CLASSNAME, "__page");
/**
 * class for carousel slider slide
 */
export var CAROUSEL_SLIDER_SLIDE = "".concat(CAROUSEL_SLIDER_BASE_CLASSNAME, "__slide");
/**
 * class for carousel slider arrows container
 */
export var CAROUSEL_SLIDER_ARROWS = "".concat(CAROUSEL_SLIDER_BASE_CLASSNAME, "__arrows");
/**
 *  Base class name for carousel slider arrow
 */
export var CAROUSEL_SLIDER_ARROW_BASE_CLASSNAME = "".concat(CAROUSEL_SLIDER_BASE_CLASSNAME, "__arrow");
/**
 *  class name for carousel slider prev arrow
 */
export var CAROUSEL_SLIDER_ARROW_PREV = "".concat(CAROUSEL_SLIDER_ARROW_BASE_CLASSNAME, "--prev");
/**
 *  class name for carousel slider next arrow
 */
export var CAROUSEL_SLIDER_ARROW_NEXT = "".concat(CAROUSEL_SLIDER_ARROW_BASE_CLASSNAME, "--next");
/**
 *  carousel content gap
 */
export var CAROUSEL_CONTENT_GAP = 0;
/**
 *  carousel arrows display
 */
export var CAROUSEL_ARROWS_DISPLAY = 'flex';
/**
 * carousel looping type
 */
export var CROUSEL_LOOPING_TYPE = 'loop';
/**
 * plide arrows
 */
export var SPLIDE_ARROWS = 'splide__arrows';
/**
 * navigation button static props
 */
export var CAROUSEL_NAVIGATION_BUTTONS_PROPS = {
    next: {
        stdIconName: 'chevron-right',
        optShape: 'circle',
        optColor: 'tertiary',
        optSize: 'standard',
        optDisabled: false,
        optInverse: false,
        business: NO_PATTERN_BUSINESS_LOGIC,
    },
    prev: {
        stdIconName: 'chevron-left',
        optShape: 'circle',
        optColor: 'tertiary',
        optSize: 'standard',
        optDisabled: false,
        optInverse: false,
        business: NO_PATTERN_BUSINESS_LOGIC,
    },
};
/**
 * class for carousel inverse
 */
export var CAROUSEL_INVERSE = "".concat(CAROUSEL_BASE_CLASSNAME, "--inverse");
/**
 * class for change aria label to german language
 */
export var I18N = {
    prev: 'Zurück',
    next: 'Weiter',
    first: 'Zurück zum Anfang',
    last: 'Zum Ende springen',
    slide: 'Karussell-Element',
    slideLabel: '%s von %s',
    carousel: 'Karussell-Element',
    select: '',
};
