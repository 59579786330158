var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import './form-selection-control.scss';
import renderTemplate from './_form-selection-control.hbs';
import { Pattern, removeUndefinedFieldsFromObject, getPropertiesFromClassName, } from '@vfde-brix/ws10/core';
import { ITEM_STATE_DISABLED, ITEM_STATE_CHECKED, SWITCH_VARIANTS, FORM_SELECTION_CONTROL_ITEM_CLASSNAME, FORM_SELECTION_CONTROL_INPUT_CLASSNAME, FORM_SELECTION_CONTROL_TEXT_CLASSNAME, FORM_SELECTION_CONTROL_BASE_CLASSNAME, } from './Constants';
/**
 * SelectionControl
 * This is a component that can be used for Radio Buttons or Checkboxes and later maybe Switches
 */
var FormSelectionControl = /** @class */ (function (_super) {
    __extends(FormSelectionControl, _super);
    function FormSelectionControl() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        /**
         * On selecting a value
         */
        _this.handleOnChange = function (event) {
            var target = event.target;
            _this.updateItemState(target);
            _this.properties.business.onChange(event, target.value);
        };
        return _this;
    }
    FormSelectionControl.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    FormSelectionControl.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    FormSelectionControl.prototype.initEvents = function () {
        var inputs = this.containerElement.getElementsByTagName('input');
        var l = inputs.length;
        for (var i = 0; i < l; i++) {
            inputs[i].addEventListener('change', this.handleOnChange);
            this.removeFocusOnClick(inputs[i]);
        }
    };
    FormSelectionControl.prototype.readDom = function (patternBusinessLogic) {
        var e_1, _a;
        var selectionItems = __spreadArray([], __read(this.containerElement.getElementsByClassName(FORM_SELECTION_CONTROL_ITEM_CLASSNAME)), false);
        var items = [];
        // We need this later
        var inputElement;
        try {
            for (var selectionItems_1 = __values(selectionItems), selectionItems_1_1 = selectionItems_1.next(); !selectionItems_1_1.done; selectionItems_1_1 = selectionItems_1.next()) {
                var selectionItem = selectionItems_1_1.value;
                var txtLabelText = selectionItem.getElementsByClassName(FORM_SELECTION_CONTROL_TEXT_CLASSNAME)[0].innerHTML.trim();
                inputElement = selectionItem.getElementsByClassName(FORM_SELECTION_CONTROL_INPUT_CLASSNAME)[0];
                var stdValue = inputElement.value;
                var stdID = inputElement.id;
                var optState = void 0;
                if (inputElement.disabled) {
                    optState = ITEM_STATE_DISABLED;
                }
                else if (inputElement.checked) {
                    optState = ITEM_STATE_CHECKED;
                }
                var item = removeUndefinedFieldsFromObject({
                    optState: optState,
                    txtLabelText: txtLabelText,
                    stdValue: stdValue,
                    stdID: stdID,
                });
                items.push(item);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (selectionItems_1_1 && !selectionItems_1_1.done && (_a = selectionItems_1.return)) _a.call(selectionItems_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var stdGroupName = inputElement.name;
        var optType = inputElement.type;
        var switchVariant = getPropertiesFromClassName(inputElement.parentElement.className, {
            variant: SWITCH_VARIANTS,
        });
        var optVariant = switchVariant.variant;
        return removeUndefinedFieldsFromObject({
            stdGroupName: stdGroupName,
            optType: optType,
            optVariant: optVariant,
            items: items,
            business: patternBusinessLogic,
        });
    };
    FormSelectionControl.prototype.updateItemState = function (target) {
        var itemIndex = Array.from(this.containerElement.getElementsByClassName(FORM_SELECTION_CONTROL_BASE_CLASSNAME)[0].children).indexOf(target.parentElement);
        var items = this.getProperties().items;
        items[itemIndex] = __assign(__assign({}, items[itemIndex]), { optState: target.checked ? ITEM_STATE_CHECKED : undefined });
        this.update({
            items: items,
        }, true);
    };
    /**
     * This function remove item focus on mouse click
     */
    FormSelectionControl.prototype.removeFocusOnClick = function (item) {
        item.addEventListener('click', function (event) {
            /* istanbul ignore next */
            if (event.screenX !== 0) {
                event.currentTarget.blur();
            }
        });
    };
    return FormSelectionControl;
}(Pattern));
export { FormSelectionControl };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of SelectionControl
 */
export var createFormSelectionControl = function (containerElement, properties) {
    var formSelectionControl = new FormSelectionControl(containerElement, properties);
    formSelectionControl.init();
    return formSelectionControl;
};
