export var OFFER_PRICE_BASE_CLASSNAME = 'ws10-offer-price';
export var OFFER_PRICE_CONTENT_CLASSNAME = "".concat(OFFER_PRICE_BASE_CLASSNAME, "__content");
export var OFFER_PRICE_CONTENT_TOP_CLASSNAME = "".concat(OFFER_PRICE_CONTENT_CLASSNAME, "--top");
export var OFFER_PRICE_CONTENT_BOTTOM_CLASSNAME = "".concat(OFFER_PRICE_CONTENT_CLASSNAME, "--bottom");
export var OFFER_PRICE_HIGHLIGHT_BADGE_CLASSNAME = "".concat(OFFER_PRICE_BASE_CLASSNAME, "__highlight-badge");
export var OFFER_PRICE_HEADLINE_CLASSNAME = "".concat(OFFER_PRICE_BASE_CLASSNAME, "__headline");
export var OFFER_PRICE_SUBLINE_TEXT_CLASSNAME = "".concat(OFFER_PRICE_BASE_CLASSNAME, "__subline-text");
export var OFFER_PRICE_PRICES_CLASSNAME = "".concat(OFFER_PRICE_BASE_CLASSNAME, "__prices");
export var OFFER_PRICE_PRICE_CLASSNAME = "".concat(OFFER_PRICE_BASE_CLASSNAME, "__price");
export var OFFER_PRICE_LEGAL_TEXT_CLASSNAME = "".concat(OFFER_PRICE_BASE_CLASSNAME, "__legal-text");
export var OFFER_PRICE_BUTTONS_CLASSNAME = "".concat(OFFER_PRICE_BASE_CLASSNAME, "__buttons");
export var OFFER_PRICE_BUTTON_CLASSNAME = "".concat(OFFER_PRICE_BASE_CLASSNAME, "__button");
export var OFFER_PRICE_ADDITIONAL_TEXT_CLASSNAME = "".concat(OFFER_PRICE_BASE_CLASSNAME, "__additional-text");
