/**
 * Base class name
 */
export var STICKY_PRICE_BAR_BASE_CLASSNAME = 'ws10-sticky-price-bar';
/**
 * Show class name
 */
export var STICKY_PRICE_BAR_INTERSECTING_CLASSNAME = "".concat(STICKY_PRICE_BAR_BASE_CLASSNAME, "--intersecting");
/**
 * Updated class name
 */
export var STICKY_PRICE_BAR_UPDATED_CLASSNAME = "".concat(STICKY_PRICE_BAR_BASE_CLASSNAME, "--updated");
/**
 * Item class name
 */
export var STICKY_PRICE_BAR_ITEM_CLASSNAME = "".concat(STICKY_PRICE_BAR_BASE_CLASSNAME, "__item");
/**
 * Item item text class name
 */
export var STICKY_PRICE_BAR_ITEM_TEXT_CLASSNAME = "".concat(STICKY_PRICE_BAR_ITEM_CLASSNAME, "-text");
/**
 * Item item price section class name
 */
export var STICKY_PRICE_BAR_ITEM_PRICE_SECTION_CLASSNAME = "".concat(STICKY_PRICE_BAR_ITEM_CLASSNAME, "-price-section");
/**
 * Item condition before class name
 */
export var STICKY_PRICE_BAR_CONDITION_BEFORE_CLASSNAME = "".concat(STICKY_PRICE_BAR_BASE_CLASSNAME, "__condition-before");
/**
 * Item price class name
 */
export var STICKY_PRICE_BAR_PRICE_CLASSNAME = "".concat(STICKY_PRICE_BAR_BASE_CLASSNAME, "__price");
