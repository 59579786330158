/**
 * Price class to show prices
 */
var Price = /** @class */ (function () {
    /**
     * Price constructor
     */
    function Price(euro, cent) {
        if (typeof euro === 'string') {
            euro = parseInt(euro, 10);
        }
        if (typeof cent === 'string') {
            cent = parseInt(cent, 10);
        }
        this.euro = euro;
        this.cent = cent;
    }
    /**
     * Returns a price from a string
     */
    Price.fromString = function (incomingString) {
        // digital separator normalization "99.99" is as valid as "99,99"
        incomingString = incomingString.replace(',', '.');
        var parts = incomingString.split('.');
        var euro = parseInt(parts[0], 10);
        // No cents given
        if (undefined === parts[1]) {
            parts[1] = '00';
        }
        // Cent should be always two digits, so incomingString "9,1" should be padded to 9,10
        if (parts[1].length === 1) {
            parts[1] += '0';
        }
        var cent = parseInt(parts[1], 10);
        return new Price(euro, cent);
    };
    /**
     * Returns a price from a number
     */
    Price.fromNumber = function (incomingNumber) {
        return this.fromString(incomingNumber.toString());
    };
    // @TODO Is this more or less view method needed at all?
    /**
     * Render price as string
     * @param options An optional options object
     * @param options.cutDecimalZeroes If true, decimal zeroes are not added (default = false)
     */
    Price.prototype.toString = function (_a) {
        var _b = _a === void 0 ? { cutDecimalZeroes: false } : _a, _c = _b.cutDecimalZeroes, cutDecimalZeroes = _c === void 0 ? false : _c;
        var priceString = "".concat(this.euro.toString());
        if (this.cent === 0) {
            if (!cutDecimalZeroes) {
                priceString += ',00';
            }
        }
        else if (this.cent < 10) {
            priceString += (",0".concat(this.cent.toString()));
        }
        else {
            priceString += ",".concat(this.cent.toString());
        }
        return priceString;
    };
    return Price;
}());
export { Price };
