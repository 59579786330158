/**
 * Base class name
 */
export var NOTIFICATION_ICON_BASE_CLASSNAME = 'ws10-notification-icon';
/**
 * Color Variations
 */
export var NOTIFICATION_ICON_STATES = [
    'success',
    'info',
    'error',
];
